import React from 'react';
import { Link } from 'react-router-dom';
import AddDancer from './AddDancer';
import {Button} from '../components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';

const Start = ({studio}) => {
	const [dancers, setDancers] = React.useState([]);
	const [noStudio, setNoStudio] = React.useState(studio === 'UNK');

	const startRef = React.useRef();

	sessionStorage.setItem('studio', studio);
	React.useEffect(() => {
		let savedDancers = JSON.parse(sessionStorage.getItem('dancers'));
		if(savedDancers) {
			setDancers(savedDancers);
		}
	}, []);
	
	return (
		<div>
			<section className="flex justify-between flex-col md:flex-row items-start p-4 md:p-6 mt-8 m-auto max-w-6xl">
			{!noStudio 
				? 
				// studio === 'ENC' ? (
				// 	<div className="w-full md:w-3/4 px-4 m-auto text-center">
				// 		<h1 className="text-4xl font-bold mb-8">2022 Dance photo orders are now closed. We'll see you on picture day!</h1>
				// 		<p>Contact <a href="mailto:info@tapschoolphotos.com" className="text-blue-600 underline">info@tapschoolphotos.com</a> with questions.</p>
				// 	</div>
				// ) : (
				<>
					<div className="w-full md:w-2/3 md:pr-10 order-2 md:order-1">
						<h2 className="text-3xl md:text-5xl font-bold mb-4">2024 Online ordering is closed</h2>

						<p className='text-base'>
							For last minute orders, you can pay at the studio with cash, check, or Venmo.
						</p>


						{/* <p className="text-base">
							We are excited to introduce our PAPERLESS ORDERING system!  Follow the simple instructions below to get started.
						</p>
						<ol className="list-decimal m-8 mt-4">
							<li className="mb-4 text-base">Add your dancers on the form on this page. Note - Please use the name your dancer is enrolled with.</li>
							<li className="mb-4 text-base">On the next page, find your classes and performances and select the photo options you want to purchase.</li>
							<li className="mb-4 text-base">That's it! Once you've made all of your photo choices, continue to checkout.</li>
						</ol>

						<p className="text-xl font-bold my-4 md:my-8">
							ORDER DEADLINE - {studio === 'WCTDJR' && `Thursday, March 30.`}{studio === 'ENC' && `Friday, April 12`}{studio === 'WCTDSR' && `Wednesday, May 3.`}
						</p> */}
						
						
						{/* <div className="flex justify-center md:hidden my-4">
							<Button type="button" buttonStyle="primary" onClick={() => startRef.current.scrollIntoView({behavior: 'smooth'})}>Start your order</Button>
						</div> */}
					</div>
					
					{/* <div ref={startRef} className="md:w-1/3 order-1 md:order-2 mb-8">
						<div className="p-8 rounded-lg bg-teal-800 text-white shadow-lg mb-8">
							<h2 className="text-3xl font-bold mb-4">Let's Get Started!</h2>
							<p className="mb-6 text-sm">Begin by adding all of your enrolled dancers full names to the list below. When you've finished adding all of your dancers, click continue.</p>
							<AddDancer dancers={dancers} setDancers={setDancers} />
							<h3 className="text-lg font-bold mb-2">
								{dancers.length > 0 ? `My Saved Dancers` : null}
							</h3>
							<div className="mb-6">
								{dancers.map((dancer, i) => {
									return (
									<p key={i} className="text-lg mb-4 px-4 py-2 rounded-lg shadow bg-teal-600 text-white flex justify-between items-center">
										<span className="font-bold">{dancer}</span>
										<FontAwesomeIcon icon={faTrashAlt} className="cursor-pointer hover:text-slate-300" onClick={() => {
											let updateDancers = dancers.filter(d => d !== dancer);
												sessionStorage.setItem('dancers', JSON.stringify(updateDancers));
												setDancers(updateDancers);
										}} />
									</p>);
								})}
							</div>
						</div>
						{dancers.length > 0 ? (
						<div className="p-8 rounded-lg bg-gray-200 shadow-lg">
							<p className="font-bold mb-4 text-xl text-center">Finished adding dancers?</p>
							<Link to="/order" className="flex justify-center">
								<Button buttonStyle="primary">Find my classes</Button>
							</Link>
						</div>
					) : null}
					</div> */}
					
				</>
				// )
				: (
					<div className="w-full md:w-3/4 px-4 m-auto">
						<h1 className="text-4xl font-bold mb-8">You’re almost there!</h1>
						<p className="text-lg">Please rescan your studio's QR code, refer to the instructions in your recital information, or contact your studio for access to the ordering system.</p>
					</div>
				)}
			</section>
			
		</div>
	);
};

export default Start;
