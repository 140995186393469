import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Root from './Root';

import { BrowserRouter as Router, Link } from 'react-router-dom';

import {Button} from './components';
import { deleteAllData } from './utils';

import LogRocket from 'logrocket';
window.location.hostname !== 'localhost' &&
	LogRocket.init('ccs-strategies/dance-orders');

function App() {
	const studio = sessionStorage.getItem('studio');
	const copyrightYear = new Date().getFullYear();
	return (
		<div className="flex flex-col h-screen font-sans">
			<Router>
				<header className="flex py-4 px-4 md:px-8 justify-between items-center bg-teal-600 text-slate-100">
					<div>
						{/* <Link to={`/${studio}`}> */}
							<img src="../TAP-logo-2.png" alt="TAP Studios Logo" className="h-24 min-w-[200px]" />
						{/* </Link> */}
					</div>	
					<div className="ml-4 text-right">
						<h1 className="text-xl md:text-4xl text-white">Online Ordering System</h1>
					</div>
				</header>
				<div className="w-full px-4 md:px-8 max-w-screen-2xl m-auto mt-0">
					<Root />
				</div>
				<footer className="mt-auto flex justify-between py-8 px-8 bg-teal-600 text-slate-100">
					<div>
						<p className="text-sm">&copy; {copyrightYear}, TAP School Photos, LLC. All rights reserved. </p>
					</div>
					<div>
						<p className="text-sm">
							<a href="https://tapschoolphotos.com/contact" target="_blank" rel="noopener noreferrer" className="underline hover:no-underline underline-offset-1">Report a problem</a>
						</p>
					</div>
				</footer>
			</Router>
		</div>
	);
}

export default App;
