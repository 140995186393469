import * as React from 'react';
import { Button } from '../components';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';

import {PaymentElement} from '@stripe/react-stripe-js';
import { TailSpin } from  'react-loader-spinner';

const Cart = ({performanceOrders, isCheckout, isLoading, stripe, elements, message}) => {
	// console.log(elements);
	// console.log(performanceOrders);
	const studio = sessionStorage.getItem('studio');

	let total = performanceOrders.reduce((acc, order) => {
		let individualPrice = order.individualPrice;
		let groupPrice = order.groupPrice;
		let bestiePrice = order.bestiePrice;
		individualPrice = individualPrice ? individualPrice : 0;
		groupPrice = groupPrice ? groupPrice : 0;
		bestiePrice = bestiePrice ? bestiePrice : 0;

		let total = individualPrice + groupPrice + bestiePrice;

		return acc + total;
	}, 0);

	const stripeAmount = total * 100;
	sessionStorage.setItem('totalPrice', stripeAmount);

	// const [validOrders, setValidOrders] = React.useState([]);

	//Prices
	//Individual: $45.50 (up to 2 costumes or individual + group from 1 costume)
	//Each Additional individual: $11.75 each
	//Group: $5.78 each
	//Bestie: $5.78 each

	// console.log(total);
	const validateOrder = () => {
		// const noDancer = performanceOrders.filter(order => order.dancer === "").length > 0;
		// const noOptions = performanceOrders.filter(order => order.individual === false && order.group === false && order.bestie === false).length > 0;
		// if(studio === 'KDS' || studio === 'WCTDJR') {
			// console.log(performanceOrders);
			//filter performanceOrders to only include orders with at least one option selected (individual, group, or bestie)
			const validOrders = performanceOrders.filter(order => order.individual === true || order.group === true || order.bestie === true);
			// console.log(validOrders);
			if(validOrders.length > 0) {
				// sessionStorage.setItem('performanceOrders', JSON.stringify(validOrders));
				return false;
			} 
			return true;
		// } else {
		// 	return noDancer || noOptions;
		// }
	}

	return (
		<div className={`bg-gray-200 rounded-lg shadow-lg p-4 md:p-8 mt-4 ${!isCheckout && studio !== 'KDS' ? `md:mt-28`:null}`}>
			<p className="font-bold text-xl">
				<FontAwesomeIcon icon={faShoppingCart} className="text-2xl mr-2" />Your Cart
			</p>

			{performanceOrders.map((dance, i) => {
				// console.log(dance)
				if(!dance.individual && !dance.bestie && !dance.group) return null;
				const itemSubtotal = dance.individualPrice + dance.groupPrice + dance.bestiePrice;
					return (
						<div key={dance.performanceOrderId} className="order border-b border-slate-700 border-opacity-60 py-2">
							<div className="item flex justify-between items-center">
								<div className="">
									<p className="font-bold">{dance.performance.title} - {dance.dancer ? dance.dancer : `Select dancer`}</p>
									{dance.individual 
										? (<p className="text-sm italic px-2">
											{dance.individualPrice !== undefined 
												? dance.individualPrice !== 0 
													? `Individual $${dance.individualPrice}` : `Individual - Included`
												: `Select dancer`}</p>
										) 
										: null
									}
									{dance.bestie
										? (<p className="text-sm italic px-2">
											{dance.bestiePrice !== undefined 
												? dance.bestiePrice !== 0 
													?`Bestie $${dance.bestiePrice}` : `Bestie - Included`
												: `Select dancer`}</p>)
										: null
									}
									{dance.group
										? (<p className="text-sm italic px-2">
											{dance.groupPrice !== undefined 
												? dance.groupPrice !== 0 
													? `Group $${dance.groupPrice}` : `Group - Included`
												: `Select dancer`}</p>)
										: null
									}
								</div>
								<span className="font-bold">{itemSubtotal ? `$${itemSubtotal.toFixed(2)}` : `Included`}</span>
							</div>
						</div>
					);
				})
			}
			{isCheckout ? (
				<div className="payment flex-col items-center w-full pt-8">
					<PaymentElement id="payment-element" />
					{message && <p id="payment-message" className="text-red-600 font-semibold mt-8">{message}</p>}
				</div>
			) : null}
			<div className="cart-total flex items-center justify-between w-full pt-8">
				<p className="text-lg md:text-xl font-bold">Total: {`$${total.toFixed(2)}`}</p>
				{!isCheckout 
					? (
						<Link to="/checkout"><Button buttonStyle={validateOrder() ? 'disabled' : 'primary'}>Checkout</Button></Link> 
					) : (

						<Button type="submit" buttonStyle={(!isLoading || !stripe || !elements) ? 'primary' : 'disabled'}>
							{isLoading ? <TailSpin color="#CBD5E1" height={16} width={16} /> : `Pay now`}
						</Button>
					)
				}
			</div>
		</div>
	);
};

export default Cart;
