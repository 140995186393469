import * as queries from '../graphql/queries';
import API from '@aws-amplify/api';
import algoliasearch from 'algoliasearch/lite';
const searchClient = algoliasearch(
	'ZZ5IP7BLFQ',
	'89a8bd3889fef294efd4aaaf05830dbb'
);
const searchIndex = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

export const perfOrdersByPerformance = async (performanceId) => {
	const response = await API.graphql({
		query: queries.getPerformanceOrderForPerformance,
		variables: {performanceId},
		authMode: 'AMAZON_COGNITO_USER_POOLS'
	});
	return response;
};
export const perfOrdersByKdsPerformance = async (kdsPerformanceId) => {
	console.log(kdsPerformanceId)
	const response = await API.graphql({
		query: queries.getPerformanceOrderForKdsPerformance,
		variables: {kdsPerformanceId},
		authMode: 'AMAZON_COGNITO_USER_POOLS'
	});
	console.log(response)
	return response;
};

export const searchPerformances = async (term, studio) => {
	const {hits} = await searchIndex.search(term, {
		filters: `studio: ${studio}`,
	});
	return hits;
};


export const performancesByStudio = async (studio) => {
	const studioFilter = `studio:${studio}`;
	console.log(studioFilter);
	const {hits} = await searchIndex.search(`${studio}# ${new Date().getFullYear()}`, {
		filters: studioFilter,
	});
	console.log(hits)
	return hits.map((item) => {
		return {
			performanceId: item.performanceId, 
			title: item.title
		};
	});
};
export const getPerformanceOrders = async () => {
	const response = await API.graphql({
		query: queries.listPerformanceOrders,
		authMode: 'AMAZON_COGNITO_USER_POOLS'
	});
	return response;
}