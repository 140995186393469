export async function calculatePrices(performanceOrders) {
	console.log(performanceOrders);
	function groupBy(arr, property) {
		return arr.reduce((acc, cur) => {
			acc[cur[property]] = [...acc[cur[property]] || [], cur];
			return acc;
		}, {});
	}
	const unsorted = performanceOrders;
	const withDancer = performanceOrders.filter((item) => item.dancer !== "");

	let sorted = Object.entries(groupBy(withDancer, 'dancer'));
	sorted = sorted.map((item) => item[1]);
	console.log(sorted);

	let prices = sorted.map((dancer) => {
		let groups = dancer.filter(dance => dance.group === true).length;
		let individuals = dancer.filter(dance => dance.individual === true).length;
		let besties = dancer.filter(dance => dance.bestie === true).length;
		
		console.log(individuals, groups, besties);

		let byDancer = dancer.map((order, i) => {
			if(individuals > 1 && i > 1) {
				return {
					...order,
					individualPrice: order.individual ? 13.00 : 0,
					groupPrice: order.group ? 9.00 : 0,
					bestiePrice: order.bestie ? 9.00 : 0,
				}
			}
			return {
				...order,
				individualPrice: order.individual ? 45.00 : 0,
				groupPrice: order.group ? 9.00 : 0,
				bestiePrice: order.bestie ? 9.00 : 0,
			}
		});
		// console.log(byDancer);

		if(individuals > 1) {
			let individual = byDancer.filter(dance => dance.individual === true)[1];
			console.log(individual);
			byDancer[byDancer.indexOf(individual)].individualPrice = 0;
		} else if (individuals === 1 && groups > 0) {
			let group = byDancer.filter(dance => dance.group === true)[0];
			console.log(group);
			byDancer[byDancer.indexOf(group)].groupPrice = 0;
		}
		else if (individuals === 1 && besties > 0) {
			// find first item in byDancer that has bestie
			let bestie = byDancer.filter((item) => item.bestie === true)[0];
			console.log(bestie);
			byDancer[byDancer.indexOf(bestie)].bestiePrice = 0;
		}
		else if (individuals === 0 && besties === 1) {
			// find first item in byDancer that has bestie
			let bestie = byDancer.filter((item) => item.bestie === true)[0];
			let bestie2 = byDancer.filter((item) => item.bestie === true)[1];
			let group = byDancer.filter((item) => item.group === true)[0];
			console.log(bestie);
			byDancer[byDancer.indexOf(bestie)].bestiePrice = 45.0;
			bestie2 && (byDancer[byDancer.indexOf(bestie2)].bestiePrice = 0);
			group && (byDancer[byDancer.indexOf(group)].groupPrice = 0);
		}
		else if (individuals === 0 && besties > 1) {
			// find first item in byDancer that has bestie
			let bestie = byDancer.filter((item) => item.bestie === true)[0];
			let bestie2 = byDancer.filter((item) => item.bestie === true)[1];
			let group = byDancer.filter((item) => item.group === true)[0];
			console.log(bestie);
			byDancer[byDancer.indexOf(bestie)].bestiePrice = 45.0;
			bestie2 && (byDancer[byDancer.indexOf(bestie2)].bestiePrice = 0);
		}
		
		return byDancer;
	});

	let result = await prices.flat(2);
	//restore the sort order of results to the original order stored in unsorted, sort by performanceOrderId
	result = result.sort((a, b) => unsorted.findIndex(item => item.performanceOrderId === a.performanceOrderId) - unsorted.findIndex(item => item.performanceOrderId === b.performanceOrderId));
	//combine sorted and unsorted
	result = result.concat(unsorted.filter(item => !result.some(item2 => item2.performanceOrderId === item.performanceOrderId)));
	
	// console.log(result);
	return result;
}