import * as React from 'react';
import { Link } from 'react-router-dom';
import PerformanceOrder from './PerformanceOrder';
import Cart from '../Cart';
import Search from './Search';
import DancerSearch from './DancerSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import algoliasearch from 'algoliasearch/lite';

import API from '@aws-amplify/api';
import {listPerformanceDancers} from '../graphql/queries';
import {generateUUID} from '../utils';
import { TailSpin } from  'react-loader-spinner';

const searchClient = algoliasearch(
	'BTSI5LSUU5',
	'de44e8159275bb708193b4d1a64a3c15'
);
const searchIndex = searchClient.initIndex(process.env.REACT_APP_KDS_ALGOLIA_INDEX);

const OrderForm = () => {
	const studio = sessionStorage.getItem('studio');
	const [newOrders, setNewOrders] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(true);
	const [selectedDancers, setSelectedDancers] = React.useState(sessionStorage.getItem('dancers') ? JSON.parse(sessionStorage.getItem('dancers')) : []);
	const [invalidDancers, setInvalidDancers] = React.useState([]);
	const [performanceOrders, setPerformanceOrders] = React.useState(sessionStorage.getItem('performanceOrders') ? JSON.parse(sessionStorage.getItem('performanceOrders')) : []);
	const cartRef = React.useRef();

	const findKdsDancer = async (term) => {
		console.log(studio)
		const {hits} = await searchIndex.search(term, {
			filters: `studio:${studio}`,
		})
		return hits;
	};

	const getPerformancesForDancer = async (dancerId) => {
			const response = await API.graphql({
				query: listPerformanceDancers,
				variables: {
					limit: 11000,
					filter: {
						kDSDancerID: {
							eq: dancerId
						}
					}
				}
			});
			console.log(response);
		
			let results = response.data.listPerformanceDancers.items.map((item) => {
				let name = item.kDSDancer.name.split(' ');
				name = name.map((n, index) => {
					return n.charAt(0).toUpperCase() + n.slice(1);
				}).join(' ');
				
				return {
					performanceOrderId: `PerformanceOrder#${generateUUID()}`,
					dancer: name,
					dancerId: item.kDSDancerID,
					performance: {
						performanceId: item.kDSPerformance.performanceId,
						title: item.kDSPerformance.performanceTitle || item.kDSPerformance.className,
						class: item.kDSPerformance.className,
						time: item.kDSPerformance.time,
						instructor: item.kDSPerformance.instructor,
						studio: item.kDSPerformance.studio,
					},
					individual: false,
					bestie: false,
					group: false,
				}
			});
			console.log(results)
			return results;
	};

	React.useEffect(() => {
		console.log(performanceOrders);
		if(studio !== 'KDS' && studio !== 'WCTDJR' && studio !== 'ENC' && studio !== 'WCTDSR') return;
		const dancers = selectedDancers;
		console.log(dancers);
		let invalid = [];
		
		if(dancers.length > 0 ) {
			//remove item from performance orders if performanceOrder.dancer doesnt exist in dancers
			let newPerformanceOrders =  performanceOrders.filter((performanceOrder) => {
				let found = false;
				dancers.forEach((dancer) => {
					if(dancer === performanceOrder.dancer) {
						found = true;
					}
				});
				return found;
			});
			console.log(newPerformanceOrders);
			
			let newDancers = dancers.filter((dancer) => !newPerformanceOrders.some((order) => order.dancer === dancer));
			console.log(newDancers)
			if(newDancers.length === 0) {
				setIsLoading(false);
			}

			let performances = newDancers.map(async(d) => {
				let dancer = await findKdsDancer(d);
				console.log(dancer)
				if(dancer.length < 1) {
					console.log(d)
					invalid.push(d);
					setInvalidDancers(invalid);
					console.log(invalidDancers)
					return [];
				}
				dancer = dancer[0];
				console.log(dancer)
				let dancerId = dancer.dancerId;
				let classes = await getPerformancesForDancer(dancerId);
				console.log(classes);
				return classes;
			});
			let performanceDancers = [];
			Promise.all(performances).then((results) => {
				results.forEach((result) => {
					performanceDancers = performanceDancers.concat(result);
				});
				console.log(performanceDancers, newPerformanceOrders)
				let updatedOrders = newPerformanceOrders.concat(performanceDancers);
				setPerformanceOrders(updatedOrders);
				sessionStorage.setItem('performanceOrders', JSON.stringify(updatedOrders));
				setIsLoading(false);
			});
		}

	}, [studio, selectedDancers]);

	React.useEffect(() => {
		newOrders.length > 0 && setPerformanceOrders(newOrders)
	}, [newOrders]);

	if(isLoading) {
		console.log(studio, isLoading);
		return(
			<div className="w-full flex justify-center mb-8">
				<TailSpin color="#CBD5E1" height={100} width={100} />
			</div>
		)
	} else {
		return !performanceOrders.length > 0 ? (
			<div className="flex flex-col items-center justify-start h-screen py-4 md:py-14">
				<p className="text-3xl font-bold mb-4">We didn't find one of your dancers...</p>
				<p className="text-xl mb-4">Try a search. Your dancer's name must match the name they are enrolled with.</p>
				<DancerSearch selectedDancers={selectedDancers} setSelectedDancers={setSelectedDancers} invalidDancers={invalidDancers} setInvalidDancers={setInvalidDancers} />
			</div>
		) : (
			<div className="py-4 md:py-14 w-full">
				<main className="order-form grid grid-cols-3 gap-6">
					<section className="col-span-3 md:col-span-2">
						<div className="w-full flex justify-end md:hidden relative">
							{performanceOrders.length > 0 
								? (<button type="button" className="text-2xl text-right" onClick={() => cartRef.current.scrollIntoView({behavior: 'smooth'})}>
									<FontAwesomeIcon icon={faShoppingCart} />
								</button>)
								: null
							}
						</div>
						
						<>
							<Link to={`/${studio}`}>
								<p className="mb-4 text-lg font-bold text-teal-900"><FontAwesomeIcon icon={faChevronLeft} /> Back to Add Dancers</p>
							</Link>
							{/* <h1 className="text-3xl md:text-4xl font-bold mb-8">Find your classes and performances</h1>
							<Search performanceOrders={performanceOrders} setNewOrders={setNewOrders} /> */}
						</>
						{ performanceOrders.map((order, i) => {
								return <PerformanceOrder key={i} performanceOrders={performanceOrders} setPerformanceOrders={setPerformanceOrders} currentPerformanceOrder={order} />;
							})
						}
					</section>
					<aside className="col-span-3 md:col-span-1 row-auto" ref={cartRef}>
							<div className="my-8">
								<h3 className="text-2xl font-bold my-2">Selected Dancers</h3>
								<ul>
									{selectedDancers.map((dancer, i) => {
										if(invalidDancers.includes(dancer)) {
											return <li key={i} className="text-red-500 cursor-default text-lg font-bold" title="We couldn't find this dancer. Re-enter this dancer's with the spelling that matches their enrolled name.">{dancer} <FontAwesomeIcon icon={faInfoCircle} /></li>
										}
										return <li key={i} className="text-lg font-bold">{dancer}</li>;
									})}
								</ul>
							</div>
						<Cart performanceOrders={performanceOrders}  />
					</aside>
				</main>
			</div>
		);
	}
};

export default OrderForm;
