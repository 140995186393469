import { useState } from 'react'
import {Button} from '../../components';
import {generateUUID} from '../../utils';
import { newKdsPerformance, newPerformanceDancer } from '../api';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
	'BTSI5LSUU5',
	'de44e8159275bb708193b4d1a64a3c15'
);
const searchIndex = searchClient.initIndex(process.env.REACT_APP_KDS_ALGOLIA_INDEX);

export function KdsCsvReader({kdsData, setKdsData}){
    const [csvFile, setCsvFile] = useState();
    
    // [{name: "", age: 0, rank: ""},{name: "", age: 0, rank: ""}]

    const processCSV = (str, delim=',') => {
        const headers = str.slice(0,str.indexOf('\n')).split(delim);
        const rows = str.slice(str.indexOf('\n')+1).split('\n');
        console.log(headers, rows);
        let newArray = rows.map( row => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj, header, i) => {
				if(!values[i]) return null;
                if(!obj) return null;
                obj[header] = values[i];
                return obj;
            }, {})
            return eachObject;
        });
        console.log(newArray);
        return newArray;
    }

    const parseData = (data) => {
        // console.log(data);
        // let performances = [];
        // newArray.forEach((performance) => {
        //     if(!performance) return;
        //     if(!performances.length > 0) {
        //         performances.push(performance);
        //     } else {
        //         if(performances.find((p) => {
        //             return (p.className === performance.className && p.time === performance.time)
        //         })) {
        //             return;
        //         } else {
        //             performances.push(performance);
        //         }
        //     }
        // });
        // console.log(performances);

        let performances = [];
        data.forEach((item) => {
            if(!item) return;
            //if performance array contains item with matching className and time, add dancer to that item, else create new item
            if(performances.length > 0) {
                let found = false;
                performances.forEach((obj, i) => {
                    // console.log(dance)
                    let dance = {
                        dancers: [],
                        ...obj,
                    }
                    if(obj.className === item.className && obj.time === item.time) {
                        dance.dancers.push(item.dancer);
                        performances[i] = dance;
                        found = true;
                    }
                });
                if(!found) {
                    performances.push({dancers: [item.dancer], ...item});
                }
            } else {
                performances.push({dancers: [item.dancer], ...item});
            }
        });
        console.log(performances)
        let performancesArray = [];
        let dancerIdArray = [];
        let performanceDancers = [];
        performances.forEach((performance) => {
            let {className, time, dancers, costume, instructor, performanceTitle, studio} = performance;
            let performanceId = `${studio}#${generateUUID()}`;
            performancesArray.push({
                performanceId,
                className,
                time,
                costume,
                instructor,
                performanceTitle,
                studio
            });

            dancers.forEach((dancer) => {
                let dancerId;
                //find dancer object in dancerIdArray by key or push to it
                let existingId = dancerIdArray.find((d) => d.name === dancer);
                if(existingId) {
                    dancerId = existingId.dancerId;
                } else {
                    dancerId = `${studio}#${dancer.split(' ').join('-')}#${generateUUID()}`;
                    dancerIdArray.push({name: dancer, dancerId: dancerId, studio: studio});
                }
                // console.log(dancerId);
                
                performanceDancers.push({
                    kDSDancerID: dancerId,
                    kDSPerformanceID: performanceId,
                });
            });
        });
        console.log(performancesArray);
        console.log(dancerIdArray);
        console.log(performanceDancers);

        setKdsData({
            performances: performancesArray,
            dancers: dancerIdArray,
            performanceDancers,
        });

    }

    const searchDancer = async (dancer) => {
        const {hits} = await searchIndex.search(dancer)
        return hits;
    }

    // const parseKdsPdcData = async (data) => {
    //     const pdcId = `KDS#${generateUUID()}`;
    //         const pdcJrId = `KDS#${generateUUID()}`;

    //         let promises = await data.map(async (d) => {
    //             if(!d) return null;
    //             let dancer = d.dancer;
    //             let id = await searchDancer(dancer);
    //             console.log(id);
    //             return {
    //                 kDSDancerID: id[0].dancerId,
    //                 kDSPerformanceID: d.className === 'PDC' ? pdcId : pdcJrId,
    //             };
    //         });
    //         let performanceDancers = await Promise.all(promises);
    //         console.log(performanceDancers.flat(2));

    //         const kdsState = {
    //             performances: [
    //                 {
    //                     studio: 'KDS',
    //                     className: 'PDC',
    //                     performanceId: pdcId,
    //                 }, 
    //                 {
    //                     studio: 'KDS',
    //                     className: 'Junior PDC',
    //                     performanceId: pdcJrId,
    //                 }
    //             ],
    //             dancers: [],
    //             performanceDancers
    //         };
    //         console.log(kdsState);
    //         let performancePromises = kdsState.performances.map(async (performance) => {
	// 			await newKdsPerformance(performance);
	// 		});
    //         let create = Promise.all(performancePromises);
    //         create.then(() => {
    //             kdsState.performanceDancers.forEach((performanceDancer) => {
    //                 if(!performanceDancer) return;
	// 				newPerformanceDancer(performanceDancer);
	// 				setTimeout(() => {
	// 					console.log('done');
	// 				}, 10);
	// 			});
    //         });
    //     }

    const submit = () => {
        const file = csvFile;
        const reader = new FileReader();

        reader.onload = async function(e) {
            const text = e.target.result;
            // console.log(text);
            let data = await processCSV(text);
            console.log(data);
            
            // await parseKdsPdcData(data);

            await parseData(data)
            
        }

        reader.readAsText(file);
    }

    return(
        <form id='kds-form'>
            <h2>Upload KDS performances</h2>
            <input
                type='file'
                accept='.csv'
                id='kdsFile'
                onChange={(e) => {
                    setCsvFile(e.target.files[0])
                }}
            >
            </input>
            <br />
            <Button
                buttonStyle='primary'
                onClick={(e) => {
                    e.preventDefault()
                    if(csvFile)submit()
                }}
            >
                Submit
            </Button>
            <br />
            {/* {setKdsData.length>0 ? 
            <>
                <table>
					<thead>
						<tr>
							<th>Studio</th>
							<th>Performance Title</th>
                            <th>Class Name</th>
							<th>Time</th>
							<th>Instructor</th>
							<th>Costume</th>
						</tr>
					</thead>
					<tbody>
                        {
                            setKdsData.map((item, i) => {
								// console.log(item);
								return (
									<tr key={i}>
										<td>{item.studio}</td>
										<td>{item.performanceTitle}</td>
                                        <td>{item.className}</td>
										<td>{item.time}</td>
										<td>{item.instructor}</td>
										<td>{item.costume}</td>
									</tr>
								)
							})
                        }
                    </tbody>
                </table>
            </> : null} */}
        </form>
    );

}