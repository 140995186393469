import * as React from 'react';
import { calculatePrices } from '../../utils/calculatePrices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';

const PerformanceOrder = ({currentPerformanceOrder, performanceOrders, setPerformanceOrders}) => {
	console.log(currentPerformanceOrder);
	// const [dancer, setDancer] = React.useState(currentPerformanceOrder.dancer || '');
	const [model, setModel] = React.useState(currentPerformanceOrder);
	const dancersList = JSON.parse(sessionStorage.getItem('dancers'));

	const updateOptions = (e) => {
		// e.preventDefault();
		const key = e.target.name;
		const value = e.target.value === 'on' ? e.target.checked : e.target.value;
		let order = model;
		order[key] = value;
		setModel(order);
		updatePerformanceOrder();
	}
	const updateDancer = (newDancer) => {
		// e.preventDefault();
		let order = model;
		order.dancer = newDancer;
		setModel(order);
		updatePerformanceOrder();
	};
	const updatePerformanceOrder = async () => {
		let orders = performanceOrders;
		let currentOrder = performanceOrders[orders.findIndex(order => order.performanceOrderId === currentPerformanceOrder.performanceOrderId)];
		// console.log(currentOrder);
		if(!currentOrder) {
			orders.push(model);
			let newOrders = await calculatePrices(orders);
			setPerformanceOrders(newOrders)
			sessionStorage.setItem('performanceOrders', JSON.stringify(newOrders));
		} else {
			orders = orders.map((order) => {
				return order.performanceOrderId === currentPerformanceOrder.performanceOrderId ? model : order;
			});
			// console.log(orders);
			let newOrders = await calculatePrices(orders);
			setPerformanceOrders(newOrders);
			sessionStorage.setItem('performanceOrders', JSON.stringify(newOrders));
		}
	}
	const removePerformance = async () => {
		let orders = performanceOrders;
		let index = orders.findIndex(order => order.performanceOrderId === currentPerformanceOrder.performanceOrderId);
		orders.splice(index, 1);
		let newOrders = await calculatePrices(orders);
		setPerformanceOrders(newOrders);
		sessionStorage.setItem('performanceOrders', JSON.stringify(newOrders));
	}

	// React.useEffect(updateDancer, [dancer]);
	// console.log(currentPerformanceOrder);
	return (
		<form>
			<div className="flex rounded-lg mb-6 shadow-lg bg-teal-700">
			{/* <div className="flex-none w-48 relative overflow-hidden">
				<img src="https://via.placeholder.com/150x200" alt="dance" className="absolute inset-0 w-full h-full object-cover rounded-l-lg" />
			</div> */}
			
				<div className="flex flex-col md:flex-row items-center text-lg text-slate-100 font-medium w-full p-4 md:px-8 py-2 pr-4">
					<div className="w-full flex justify-between md:block md:w-1/2 mb-2 md:mb-0">
						<div >
							<h3 className="text-xl font-extrabold">{currentPerformanceOrder.performance.class ? currentPerformanceOrder.performance.class : currentPerformanceOrder.performance.title}</h3>
							{currentPerformanceOrder.performance.class ? (<p className="text-sm font-semibold">{currentPerformanceOrder.performance.title}</p>) : null}
							{currentPerformanceOrder.performance.days && <p className="text-sm font-semibold">{currentPerformanceOrder.performance.days}</p>}
							<p className="text-sm font-semibold">{currentPerformanceOrder.performance.time}</p>
						</div>
						<div className="text-right md:text-left">
							<button type="button" className="ml-auto mb-2 md:mb-0 md:hidden" onClick={() => removePerformance()}>
								<FontAwesomeIcon icon={faTrashAlt} className="text-white" />
							</button>
							<p className="text-sm font-semibold">{currentPerformanceOrder.performance.instructor}</p>
							{currentPerformanceOrder.performance.song ? (
								<p className="text-sm font-semibold"><em>"{currentPerformanceOrder.performance.song}"</em></p>
							) : null}
						</div>
					</div>
					<div className="w-full md:w-1/2 ml-auto">
						<div className="p-0 md:p-4 pt-0 my-4 md:my-0 flex justify-between items-top">
							<div className="w-full md:w-[80%] box-border">
								{/* <label htmlFor="dancer" className="inline-flex items-center text-sm">Select a Dancer</label> */}
								
								
								<p className="text-2xl font-bold text-center ml-[10%]">{currentPerformanceOrder.dancer}</p>
								
							</div>
						</div>
						<div className="flex items-center justify-around font-semibold text-lg">
							<div className="flex flex-col items-center justify-center">
								<label htmlFor="individual" className="mb-2 text-center">
									Individual
									{/* <span className="block text-sm">{`($${index < 2 ? `45.50` : `11.75`})`}</span> */}
								</label>
								<input 
									type="checkbox" 
									className="w-6 h-6 rounded-md checked:text-teal-400"
									name="individual" 
									id="individual"
									checked={currentPerformanceOrder.individual || false}
									onChange={(e) => updateOptions(e)} />
							</div>
							{currentPerformanceOrder.performance.studio !== 'KDS' || (currentPerformanceOrder.performance.class !== 'PDC' && currentPerformanceOrder.performance.class !== 'Junior PDC') ? (
							<div className="flex flex-col items-center justify-center">
								<label htmlFor="group" className="mb-2 text-center">
									Group
									{/* <span className="block text-sm">(+$5.78)</span> */}
								</label>
								<input 
									type="checkbox" 
									className="w-6 h-6 rounded-md checked:text-teal-400" 
									name="group" id="group" 
									checked={currentPerformanceOrder.group || false}
									onChange={(e) => updateOptions(e)} />
							</div>
							) : null}
							<div className="flex flex-col items-center justify-center">
								<label htmlFor="bestie" className="mb-2 text-center leading-none">
									Bestie
									{/* <span className="block text-sm">(+$5.78)</span> */}
								</label>
								<input 
									type="checkbox" 
									className="w-6 h-6 rounded-md checked:text-teal-400" 
									name="bestie" id="bestie" 
									checked={currentPerformanceOrder.bestie || false}
									onChange={(e) => updateOptions(e)} />
							</div>
						</div>
					</div>
				</div>	
			</div>
		</form>
	);
};

export default PerformanceOrder;
